import React from 'react';
import classNames from 'classnames';
import Pagination from '../../../common/components/pagination';
import styles from './pagination-comments.scss';

const PaginationComments = props => (
  <div className={styles.container}>
    <Pagination
      className={styles.pagination}
      arrowClass={styles.arrow}
      innerClass={styles.page}
      activeClass={classNames(styles.active, 'blog-button-color')}
      {...props}
    />
  </div>
);

export default PaginationComments;

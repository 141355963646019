import React from 'react';
import { connect } from '../runtime-context';
import CommentAvatar from '../comment-avatar';
import { getCurrentUser } from '@wix/communities-blog-client-common/dist/src/selectors/current-user-selectors';

const CurrentUserAvatar = props => <CommentAvatar {...props} />;

const mapRuntimeToProps = state => ({
  user: getCurrentUser(state),
});

export default connect(mapRuntimeToProps)(CurrentUserAvatar);

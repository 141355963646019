import { forOwn } from 'lodash';
import { FIELD_ERROR_REQUIRED } from '@wix/communities-blog-client-common/dist/src/constants/field-errors';

export default function getEmptyFields(errors) {
  const emptyFields = [];
  forOwn(errors, (error, field) => {
    if (error === FIELD_ERROR_REQUIRED) {
      emptyFields.push(field);
    }
  });
  return emptyFields;
}

import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';

import withTranslate from '../../../common/hoc/with-translate';
import { getPostEditorDraftKey } from '../../../common/store/post-editor/post-editor-selectors';
import { getIsSavingPostDraft } from '../../../common/store/is-loading/is-loading-selectors';
import { getIsPostDraftSaved } from '../../../common/store/is-loaded/is-loaded-selectors';
import styles from './post-form-saving-status.scss';

export const PostFormSavingStatus = ({ isSavingDraft, isDraftSaved, t }) => {
  let status = null;

  if (isSavingDraft || isDraftSaved) {
    status = t(isSavingDraft ? 'post-form.status-saving' : 'post-form.status-saved');
  }

  return (
    <div className={styles.container} data-hook="post-form__status">
      {status}
    </div>
  );
};

PostFormSavingStatus.propTypes = {
  t: PropTypes.func.isRequired,
  isSavingDraft: PropTypes.bool,
  isDraftSaved: PropTypes.bool,
};

const mapRuntimeToProps = state => {
  const draftKey = getPostEditorDraftKey(state);
  return {
    isSavingDraft: getIsSavingPostDraft(state, draftKey),
    isDraftSaved: getIsPostDraftSaved(state, draftKey),
  };
};

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate
)(PostFormSavingStatus);

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ModalCentered } from '../../../common/components/modals/base/modal-centered';
import withTranslate from '../../../common/hoc/with-translate';
import Confirmation from '../../../common/components/confirmation';

export class PostFormEditInProgressModal extends Component {
  onConfirm = () => {
    this.props.closeModal(this.props.postSlug);
  };

  render() {
    const { t } = this.props;

    return (
      <ModalCentered onClose={this.onConfirm}>
        <Confirmation
          confirm={t('post-edit-in-progress-modal.got-it')}
          title={t('post-edit-in-progress-modal.cannot-edit-title')}
          onConfirm={this.onConfirm}
          buttonProps={{ isSecondaryButtonVisible: false }}
        >
          <p>{t('post-edit-in-progress-modal.cannot-edit-message')}</p>
        </Confirmation>
      </ModalCentered>
    );
  }
}

PostFormEditInProgressModal.propTypes = {
  t: PropTypes.func.isRequired,
  postSlug: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

export default withTranslate(PostFormEditInProgressModal);

import { flowRight, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';

import { MODAL_TYPE_DISCARD_POST_SETTINGS } from '@wix/communities-blog-client-common/dist/src/constants/modal-types';
import { getChangedPostSettingsValues } from '../../services/post-settings';
import POST_SETTINGS_FORM_CONFIG from '../../services/post-settings-form-config';
import POST_FORM_CONFIG from '../../../post-page/services/post-form-config';

import { convertToRaw } from '../../../common/services/post-utils-rce';
import { ModalFromRight } from '../../../common/components/modals/base/modal-from-right';
import { getPostEditorDraftKey } from '../../../common/store/post-editor/post-editor-selectors';
import PostSettingsFormLazyComponent from './post-settings-form-lazy';
import PostSettingsButtons from '../../components/post-settings-buttons';
import styles from './post-settings-modal.scss';
import withFastForm from '../../../common/components/fast-form/with-fast-form';

class PostSettingsModal extends Component {
  onClose = () => {
    const { openModal, closeModal } = this.props;
    const hasChanges = !isEmpty(this.getChanges());

    if (hasChanges) {
      openModal(MODAL_TYPE_DISCARD_POST_SETTINGS, {}, PostSettingsButtons.CANCEL_BUTTON_ID);
    } else {
      closeModal();
    }
  };

  getChanges = () => getChangedPostSettingsValues(this.props.fastForm.fields);

  // without timeout will submit data without waiting for blur event to update values
  savePostSettings = () =>
    new Promise(resolve => {
      setTimeout(() => this.props.fastForm.changeValues(POST_FORM_CONFIG, this.getChanges(), resolve));
    });

  save = () => this.savePostSettings().then(this.props.closeModal);

  saveAndPublish = () => {
    const { postId, saveAndPublishPost, closeModal, draftKey } = this.props;

    this.savePostSettings().then(values => {
      saveAndPublishPost({
        ...values,
        content: convertToRaw(values.content),
        _id: postId,
        draftKey,
      }).then(closeModal);
    });
  };

  onSubmit = publish => (publish ? this.saveAndPublish() : this.save());

  render() {
    const {
      fastForm: { isValid, fields },
      canPublish,
      isPublished,
    } = this.props;

    return (
      <ModalFromRight className={styles.container} onClose={this.onClose} backdropClassName={styles.backdrop}>
        <PostSettingsFormLazyComponent
          fields={fields}
          onSubmit={this.onSubmit}
          onCancel={this.onClose}
          invalid={!isValid}
          canPublish={canPublish}
          isPublished={isPublished}
        />
      </ModalFromRight>
    );
  }
}

PostSettingsModal.propTypes = {
  draftKey: PropTypes.string.isRequired,
  fastForm: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  saveAndPublishPost: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  draftKey: getPostEditorDraftKey(state),
  openModal: actions.openModal,
  saveAndPublishPost: actions.saveAndPublishPostPromisified,
});

// prettier-ignore
export default flowRight(
  withFastForm(POST_SETTINGS_FORM_CONFIG, { resetOnUnmount: true }),
  connect(mapRuntimeToProps)
)(PostSettingsModal);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../runtime-context';

const DEFAULT_SCOPE = 'default';
const UPDATE_TIME = 400;
const MAX_PROGRESS = 99;
const PROGRESS_INCREASE = 20;
const ANIMATION_DURATION = UPDATE_TIME * 2;
const TERMINATING_ANIMATION_DURATION = UPDATE_TIME / 2;

const initialState = {
  percent: 0,
  status: 'hidden',
};

const shouldStart = (loading, status) => loading > 0 && ['hidden', 'stopping'].includes(status);
const shouldStop = (loading, status) => loading === 0 && ['starting', 'running'].includes(status);

class LoadingBar extends Component {
  state = { ...initialState };

  componentDidMount() {
    if (this.state.status === 'starting') {
      this.start();
    }
  }

  componentWillUnmount() {
    clearInterval(this.progressIntervalId);
    clearTimeout(this.terminatingAnimationTimeoutId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (shouldStart(nextProps.loading, prevState.status)) {
      return { status: 'starting' };
    }

    if (shouldStop(nextProps.loading, prevState.status)) {
      return { status: 'stopping' };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.state;
    if (prevState.status !== status) {
      if (status === 'starting') {
        this.start();
      }

      if (status === 'stopping') {
        this.stop();
      }
    }
  }

  newPercent = (percent, progressIncrease) => {
    const smoothedProgressIncrease = progressIncrease * Math.cos(percent * (Math.PI / 2 / 100));
    return percent + smoothedProgressIncrease;
  };

  simulateProgress = () => {
    this.setState((prevState, { maxProgress, progressIncrease }) => {
      let { percent } = prevState;
      const newPercent = this.newPercent(percent, progressIncrease);

      if (newPercent <= maxProgress) {
        percent = newPercent;
      }

      return { percent };
    });
  };

  start() {
    if (this.terminatingAnimationTimeoutId) {
      clearTimeout(this.terminatingAnimationTimeoutId);
      this.reset();
    }

    const { updateTime } = this.props;
    this.progressIntervalId = setInterval(this.simulateProgress, updateTime);
    this.setState({ status: 'running' });
  }

  stop() {
    const { showFastActions } = this.props;
    clearInterval(this.progressIntervalId);
    this.progressIntervalId = null;

    const terminatingAnimationDuration = this.isShown() || showFastActions ? TERMINATING_ANIMATION_DURATION : 0;

    this.terminatingAnimationTimeoutId = setTimeout(this.reset, terminatingAnimationDuration);

    this.setState({ percent: 100 });
  }

  reset = () => {
    this.terminatingAnimationTimeoutId = null;
    this.setState(initialState);
  };

  isShown() {
    const { percent } = this.state;
    return percent > 0 && percent <= 100;
  }

  render() {
    const { status } = this.state;
    const { className } = this.props;
    if (status === 'hidden') {
      return <div />;
    }

    return (
      <div>
        <div style={this.buildStyle()} className={className} />
        <div style={{ display: 'table', clear: 'both' }} />
      </div>
    );
  }

  buildStyle() {
    const { status, percent } = this.state;
    const { direction, className, style: customStyle } = this.props;

    const animationDuration = status === 'stopping' ? TERMINATING_ANIMATION_DURATION : ANIMATION_DURATION;

    const coefficient = direction === 'rtl' ? 1 : -1;
    const tx = (100 - percent) * coefficient;

    const style = {
      opacity: '1',
      transform: `translate3d(${tx}%, 0px, 0px)`,
      msTransform: `translate3d(${tx}%, 0px, 0px)`,
      WebkitTransform: `translate3d(${tx}%, 0px, 0px)`,
      MozTransform: `translate3d(${tx}%, 0px, 0px)`,
      OTransform: `translate3d(${tx}%, 0px, 0px)`,
      transition: `transform ${animationDuration}ms linear 0s`,
      msTransition: `-ms-transform ${animationDuration}ms linear 0s`,
      WebkitTransition: `-webkit-transform ${animationDuration}ms linear 0s`,
      MozTransition: `-moz-transform ${animationDuration}ms linear 0s`,
      OTransition: `-o-transform ${animationDuration}ms linear 0s`,
      width: '100%',
      willChange: 'transform, opacity',
    };

    if (!className) {
      style.height = '3px';
      style.backgroundColor = 'red';
      style.position = 'absolute';
    }

    if (this.isShown()) {
      style.opacity = '1';
    } else {
      style.opacity = '0';
    }

    return { ...style, ...customStyle };
  }
}

LoadingBar.propTypes = {
  direction: PropTypes.string,
  loading: PropTypes.number,
  maxProgress: PropTypes.number,
  progressIncrease: PropTypes.number,
  scope: PropTypes.string,
  showFastActions: PropTypes.bool,
  style: PropTypes.object,
  updateTime: PropTypes.number,
};

LoadingBar.defaultProps = {
  direction: 'ltr',
  loading: 0,
  maxProgress: MAX_PROGRESS,
  progressIncrease: PROGRESS_INCREASE,
  scope: DEFAULT_SCOPE,
  showFastActions: false,
  style: {},
  updateTime: UPDATE_TIME,
};

const mapStateToProps = (state, ownProps) => ({
  loading: state.loadingBar[ownProps.scope || DEFAULT_SCOPE],
});

export default connect(mapStateToProps)(LoadingBar);

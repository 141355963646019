import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';

import POST_CATEGORIES_FORM_CONFIG from '../../services/post-categories-form-config';
import POST_FORM_CONFIG from '../../../post-page/services/post-form-config';
import { getCategories } from '../../../common/selectors/categories-selectors';
import withFastForm from '../../../common/components/fast-form/with-fast-form';
import FullScreenModal from '../../../common/components/mobile-full-screen-modal';
import CategorySelect from '../../../common/components/category-select';
import AddCategoriesMobileHeader from './add-categories-mobile-header';

class AddCategoriesMobileModal extends Component {
  handleSubmit = () => {
    const { fastForm, closeModal } = this.props;
    fastForm.changeValues(POST_FORM_CONFIG, { categoryIds: fastForm.values.categoryIds }, closeModal);
  };

  render() {
    const {
      categories,
      closeModal,
      fastForm: {
        fields: { categoryIds },
      },
    } = this.props;
    const header = <AddCategoriesMobileHeader onCancel={() => closeModal()} onSubmit={this.handleSubmit} />;

    return (
      <FullScreenModal header={header} hasInnerScroll={false}>
        <CategorySelect categories={categories} value={categoryIds.value || []} onChange={categoryIds.onChange} />
      </FullScreenModal>
    );
  }
}

AddCategoriesMobileModal.propTypes = {
  categories: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  fastForm: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  categories: getCategories(state),
});

export default flowRight(connect(mapStateToProps), withFastForm(POST_CATEGORIES_FORM_CONFIG))(AddCategoriesMobileModal);

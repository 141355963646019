import hasActiveUploads from '@wix/communities-blog-client-common/dist/src/rich-content/services/has-active-uploads';
import { isContentStateEmpty } from '../../common/services/content-state-utils';

const COMMENT_FORM_CONFIG = {
  formName: 'comment',
  fields: ['content', 'ownerId', 'ownerSiteMemberId'],
  validate: ({ content }) => {
    if (isContentStateEmpty(content)) {
      return { content: 'empty' };
    }

    if (content && hasActiveUploads(content)) {
      return { image: 'loading' };
    }
  },
};

export default COMMENT_FORM_CONFIG;

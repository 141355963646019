import { chain, identity, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';

import {
  MODAL_TYPE_ADD_CATEGORIES,
  MODAL_TYPE_DISCARD_POST_DRAFT,
} from '@wix/communities-blog-client-common/dist/src/constants/modal-types';
import { getIsDesktop } from '../../../common/store/basic-params/basic-params-selectors';
import { getPostSettingsValues, getPostValues } from '../../../post-settings/services/post-settings';
import { getTopology } from '../../../common/store/topology/topology-selectors';
import { TAB_CATEGORIES, TAB_GENERAL } from '../../../post-settings/constants/tabs';
import POST_FORM_CONFIG from '../../services/post-form-config';
import withFastForm from '../../../common/components/fast-form/with-fast-form';
import withTranslate from '../../../common/hoc/with-translate';
import ActionButton from '../../components/action-button';
import PostForm from '../../components/post-form';

class PostFormActions extends Component {
  openPostSettingsModal = activeTab => {
    const {
      fastForm: { fields },
      isPublished,
      postId,
      openPostSettings,
    } = this.props;

    openPostSettings({
      activeTab,
      formInitialValues: getPostSettingsValues(fields),
      postValues: getPostValues(fields),
      focusOnCloseId: PostForm.FOCUSABLE_ID_POST_ACTIONS,
      isPublished,
      postId,
      canPublish: !this.props.isCtaDisabled,
    });
  };

  getButtonProps = action => {
    const {
      openModal,
      postId,
      isPublished,
      isDesktop,
      fastForm: {
        fields: { categoryIds },
      },
      onClickSave,
      onClickPublish,
    } = this.props;

    switch (action) {
      case PostFormActions.ACTION_SAVE:
        return {
          key: action,
          onClick: onClickSave,
          labelTranslationKey: isPublished
            ? 'post-form-actions.save-button-label'
            : 'post-form-actions.save-draft-button-label',
          dataHook: 'post-form-actions__save',
        };

      case PostFormActions.ACTION_DISCARD:
        return {
          key: action,
          onClick: () => openModal(MODAL_TYPE_DISCARD_POST_DRAFT, { postId }),
          labelTranslationKey: 'post-form-actions.discard-button-label',
          dataHook: 'post-form-actions__discard',
        };

      case PostFormActions.ACTION_PUBLISH:
        return {
          key: action,
          onClick: onClickPublish,
          labelTranslationKey: 'post-form-actions.publish-button-label',
          dataHook: 'post-form-actions__publish',
        };

      case PostFormActions.ACTION_ADD_CATEGORY:
        return {
          key: action,
          onClick: () =>
            isDesktop
              ? this.openPostSettingsModal(TAB_CATEGORIES)
              : openModal(MODAL_TYPE_ADD_CATEGORIES, { formInitialValues: { categoryIds: categoryIds.value || [] } }),
          labelTranslationKey: 'post-form-actions.category-button-label',
          dataHook: 'post-form-actions__add-category',
        };

      case PostFormActions.ACTION_POST_SETTINGS:
        return {
          key: action,
          onClick: () => this.openPostSettingsModal(TAB_GENERAL),
          labelTranslationKey: 'post-form-actions.post-settings-button-label',
          dataHook: 'post-form-actions__post-settings',
        };

      default:
        return null;
    }
  };

  render() {
    const { actions, t } = this.props;
    if (!actions || !actions.length) {
      return <div />;
    }

    const buttons = chain(actions)
      .map(action => this.getButtonProps(action, this.props))
      .filter(identity)
      .map(({ onClick, key, labelTranslationKey, dataHook, disabled }) => (
        <ActionButton onClick={onClick} key={key} data-hook={dataHook} disabled={disabled}>
          {t(labelTranslationKey)}
        </ActionButton>
      ))
      .value();

    return <div>{buttons}</div>;
  }
}

PostFormActions.ACTION_SAVE = 'save';
PostFormActions.ACTION_DISCARD = 'discard';
PostFormActions.ACTION_PUBLISH = 'publish';
PostFormActions.ACTION_ADD_CATEGORY = 'addCategory';
PostFormActions.ACTION_POST_SETTINGS = 'postSettings';

const ALL_ACTIONS = [
  PostFormActions.ACTION_SAVE,
  PostFormActions.ACTION_DISCARD,
  PostFormActions.ACTION_PUBLISH,
  PostFormActions.ACTION_ADD_CATEGORY,
  PostFormActions.ACTION_POST_SETTINGS,
];

PostFormActions.propTypes = {
  t: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  publish: PropTypes.func.isRequired,
  openPostSettings: PropTypes.func.isRequired,
  postId: PropTypes.string,
  invalid: PropTypes.bool,
  isPublished: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.oneOf(ALL_ACTIONS)),
  isDesktop: PropTypes.bool,
  topology: PropTypes.object,
  isPostFormFieldsEmpty: PropTypes.bool,
  unschedule: PropTypes.func.isRequired,
  isCtaDisabled: PropTypes.bool,
  onClickSave: PropTypes.func,
  onClickPublish: PropTypes.func,
  onClickCancel: PropTypes.func,
};

PostFormActions.defaultProps = {
  actions: ALL_ACTIONS,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  topology: getTopology(state),
  isDesktop: getIsDesktop(state),
  openModal: actions.openModal,
  openPostSettings: actions.openPostSettings,
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withFastForm(POST_FORM_CONFIG, { skipInitialize: true }),
  withTranslate,
)(PostFormActions);

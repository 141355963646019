import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import StickyFooter from '../sticky-footer';
import { HorizontalSeparator } from '../../../common/components/separator';
import CommentFormWrapper from '../../../comments/components/comment-form-wrapper';
import withCardBackgroundColor from '../../../common/hoc/with-card-background-color';
import styles from './post-page-footer-wrapper.scss';

export const PostPageFooterWrapper = ({
  isMobile,
  isSticky,
  stickyThreshold,
  children,
  cardBackgroundColor: backgroundColor,
}) => {
  if (!isMobile) {
    return (
      <div className={styles.container}>
        <CommentFormWrapper>{children}</CommentFormWrapper>
      </div>
    );
  }

  const contentHeight = 60;
  const containerHeight = contentHeight;
  const className = classNames(styles.container, {
    'blog-default-card-background-color': isSticky,
    'blog-card-background-color': !isSticky,
  });
  const style = isSticky ? { backgroundColor } : null;
  const content = (
    <div className={className} style={style}>
      <HorizontalSeparator />
      {children}
    </div>
  );

  if (!isSticky) {
    return content;
  }

  return (
    <StickyFooter containerHeight={containerHeight} contentHeight={contentHeight} showAfter={stickyThreshold}>
      {content}
    </StickyFooter>
  );
};

PostPageFooterWrapper.propTypes = {
  children: PropTypes.node,
  isSticky: PropTypes.bool,
  stickyThreshold: PropTypes.number,
  isMobile: PropTypes.bool,
  cardBackgroundColor: PropTypes.string.isRequired,
};

export default withCardBackgroundColor(PostPageFooterWrapper);

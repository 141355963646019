import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import withCardBackgroundColor from '../../hoc/with-card-background-color';
import { enableScroll, disableScroll } from '../../services/body-scroll';
import styles from './mobile-full-screen-modal.scss';

export class MobileFullScreenModal extends Component {
  componentDidMount() {
    disableScroll();
  }

  componentWillUnmount() {
    enableScroll();
  }

  render() {
    const { className, children, cardBackgroundColor, isAnimated, hasInnerScroll } = this.props;
    const containerClassName = classNames(styles.container, 'blog-default-card-background-color', {
      [styles.isAnimated]: isAnimated,
      [styles.disableScroll]: !hasInnerScroll,
    });
    const contentClassName = classNames(styles.content, className);
    const style = { backgroundColor: cardBackgroundColor };

    return (
      <main className={containerClassName} style={style}>
        <div className={styles.header}>{React.cloneElement(this.props.header)}</div>
        <div className={styles.scrollable}>
          <div className={contentClassName}>{children}</div>
        </div>
      </main>
    );
  }
}

MobileFullScreenModal.propTypes = {
  className: PropTypes.string,
  cardBackgroundColor: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.node,
  isAnimated: PropTypes.bool,
  hasInnerScroll: PropTypes.bool,
};

MobileFullScreenModal.defaultProps = {
  isAnimated: true,
  hasInnerScroll: true,
};

export default withCardBackgroundColor(MobileFullScreenModal);

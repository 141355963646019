import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
// import { cancelUploads } from '../../actions/cancel-uploads';
import CardOverlay from '../../../common/components/card-overlay';
import PostPageFooterWrapper from '../post-page-footer-wrapper';
import CommentBar from '../../../comments/components/comment-bar';
import CommentForm from '../../../comments/components/comment-form';
import LoginToComment from '../../../comments/components/login-to-comment';
import { HorizontalSeparator } from '../../../common/components/separator';
import forMobileOnly from '../../../common/hoc/for-mobile-only';
import withAuth from '../../../common/hoc/with-auth';
import withDeviceType from '../../../common/hoc/with-device-type';
import withFastForm from '../../../common/components/fast-form/with-fast-form';
import { convertToRaw } from '../../../common/services/post-utils-rce';
import { isEditingComment } from '../../../comments/selectors/edited-comment-id-selectors';
import processImage from '../../../common/services/process-image';

const MobileSeparator = forMobileOnly(HorizontalSeparator);

export class PostPageFooter extends Component {
  componentWillUnmount = () => {
    // this.props.cancelUploads(); HACK
  };

  handleCommentFormSubmit = ({ values }, fastForm) => {
    this.props
      .createCommentPromisified(this.props.postId, { content: convertToRaw(values.content) })
      .then(() => {
        this.props.onCommentCreate();
        fastForm.resetForm();
      })
      .catch(() => {
        fastForm.stopSubmit();
      });
  };

  handleCommentImageChange = file => {
    const { fastForm, uploadImage } = this.props;
    processImage(file).then(imageData => {
      fastForm.changeValue('image')(imageData.dataUrl);
      uploadImage(imageData.file, imageData).then(value => fastForm.changeValue('image')(value));
    });
  };

  handleCommentImageDelete = () => {
    this.props.fastForm.changeValue('image')(null);
  };

  getInitialFormValues = () => {
    const { currentUser } = this.props;

    return {
      ownerId: currentUser._id,
      ownerSiteMemberId: currentUser.siteMemberId,
    };
  };

  renderCommentBar = () => (
    <CommentBar
      formInitialValues={this.getInitialFormValues()}
      onChange={this.handleCommentChange}
      onSubmit={this.handleCommentFormSubmit}
      onImageChange={this.handleCommentImageChange}
      onImageDelete={this.handleCommentImageDelete}
    />
  );

  renderCommentForm = () => (
    <CommentForm
      formInitialValues={this.getInitialFormValues()}
      onChange={this.handleCommentChange}
      onSubmit={this.handleCommentFormSubmit}
      shouldDisableButton={!this.props.isEditingComment}
    />
  );

  render = () => {
    const {
      isMobile,
      isAuthenticated,
      isSticky,
      stickyThreshold,
      isEditingComment,
      stopEditingComment,
      postId,
    } = this.props;
    let content;

    if (!postId) {
      return null;
    }

    if (isAuthenticated) {
      content = isMobile ? this.renderCommentBar() : this.renderCommentForm();
    } else {
      content = <LoginToComment />;
    }

    return (
      <PostPageFooterWrapper isMobile={isMobile} isSticky={isSticky} stickyThreshold={stickyThreshold}>
        {isEditingComment && !isMobile ? <CardOverlay onClick={stopEditingComment} /> : null}
        {content}
        <MobileSeparator />
      </PostPageFooterWrapper>
    );
  };
}

PostPageFooter.propTypes = {
  postId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  isSticky: PropTypes.bool,
  stickyThreshold: PropTypes.number,
  uploadImage: PropTypes.func.isRequired,
  cancelUploads: PropTypes.func.isRequired,
  createCommentPromisified: PropTypes.func.isRequired,
  onCommentCreate: PropTypes.func.isRequired,
  stopEditingComment: PropTypes.func.isRequired,
  isEditingComment: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isEditingComment: isEditingComment(state),
  createCommentPromisified: actions.createCommentPromisified,
  uploadImage: actions.uploadImagePromisified,
  cancelUploads: actions.cancelUploads,
  stopEditingComment: actions.stopEditingComment,
});

PostPageFooter.defaultProps = {
  onCommentCreate: () => {},
};

export default flowRight(
  connect(mapRuntimeToProps),
  withFastForm(CommentBar.FormConfig),
  withAuth,
  withDeviceType,
)(PostPageFooter);

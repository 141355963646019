import { flowRight, invoke } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Interpolate } from 'react-i18next';
import classNames from 'classnames';

import { isContentStateEmpty } from '../../../common/services/content-state-utils';
import { getPluginConfig } from '@wix/communities-blog-client-common/dist/src/rich-content/services/viewer';
import RichContentEditor from '../../../rich-content/components/rich-content-editor';
import * as RichContent from '../../../rich-content/services/rich-content';
import CurrentUserAvatar from '../../../common/components/current-user-avatar';
import ButtonGroup from '../../../common/components/button-group';
import CurrentUserProfileLink from '../../../common/components/current-user-profile-link';
import TimeAgo from '../../../common/components/time-ago';
import COMMENT_FORM_CONFIG from '../../services/comment-form-config';
import withTranslate from '../../../common/hoc/with-translate';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withAuth from '../../../common/hoc/with-auth';
import withFastForm from '../../../common/components/fast-form/with-fast-form';
import withPostBorderWidth from '../../../common/hoc/with-post-border-width';
import styles from './comment-form.scss';

const createEditorKey = () => new Date().getTime();

export class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.PLUGINS = getPluginConfig(RichContent).PLUGINS;
    this.state = {
      editorKey: createEditorKey(),
    };
  }

  componentWillReceiveProps = ({ fastForm: { isSubmitting, values, submit } }) => {
    if (isSubmitting && !this.props.fastForm.isSubmitting) {
      submit();
    }

    if (this.props.fastForm.values.content && !values.content) {
      this.setState({
        editorKey: createEditorKey(),
      });
    }
  };

  handleContentChange = contentState => {
    this.props.fastForm.changeValue('content')(contentState);

    if (contentState && contentState.getBlockMap) {
      if (!isContentStateEmpty(contentState)) {
        invoke(this.props, 'onChange');
      }
    } else {
      const blocks = contentState.blocks;
      if (blocks[0].text || blocks.length > 1) {
        invoke(this.props, 'onChange');
      }
    }
  };

  renderEditDate = () => {
    const { editedDate } = this.props;

    if (!editedDate) {
      return null;
    }

    return (
      <p className={styles.editDate}>
        <Interpolate i18nKey="comment-form.edited" timeAgo={<TimeAgo time={editedDate} />} />
      </p>
    );
  };

  isPrimaryButtonDisabled = () => {
    const {
      fastForm: { isSubmitting, isValid },
      shouldDisableButton,
    } = this.props;

    return shouldDisableButton ? isSubmitting || !isValid : false;
  };

  submitAndShowButtonSpinner = () => {
    this.props.fastForm.submit();
  };

  renderButtons = () => {
    const { t, onCancel, forPublicUser } = this.props;
    const primaryButtonProps = {
      disabled: this.isPrimaryButtonDisabled(),
      onClick: forPublicUser(this.submitAndShowButtonSpinner),
    };
    return (
      <ButtonGroup
        primaryButtonText={t('comment-form.publish')}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonText={t('comment-form.cancel')}
        secondaryButtonProps={{ onClick: onCancel }}
        isSecondaryButtonVisible={Boolean(onCancel)}
      />
    );
  };

  render() {
    const {
      contentFontClassName,
      fastForm: { values },
      t,
      borderWidth,
    } = this.props;
    const containerClassName = classNames(styles.commentForm, contentFontClassName, 'blog-text-color', 'comment-form');
    return (
      <div className={containerClassName} style={{ borderWidth }} data-hook="comment-form">
        <div className={styles.header}>
          <CurrentUserProfileLink>
            <CurrentUserAvatar />
          </CurrentUserProfileLink>
        </div>
        {this.renderEditDate()}
        <div className={styles.content}>
          <RichContentEditor
            key={this.state.editorKey}
            className={classNames(styles.texteditor, 'comment-form__content')}
            placeholder={t('text-editor.comment-placeholder')}
            onChange={this.handleContentChange}
            initialState={values.content || undefined}
            plugins={[this.PLUGINS.IMAGE, this.PLUGINS.VIDEO]}
            externalModalsEnabled
            showAdvancedImageSettings={false}
            useDescriptionFontInEditor={false}
            isCompact
          />
        </div>
        <div className={styles.buttonContainer}>{this.renderButtons()}</div>
      </div>
    );
  }
}

CommentForm.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  fastForm: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  shouldDisableButton: PropTypes.bool,
  editedDate: PropTypes.string,
  forPublicUser: PropTypes.func,
  borderWidth: PropTypes.number.isRequired,
};

CommentForm.defaultProps = {
  shouldDisableButton: true,
};

export default flowRight(
  withFastForm(COMMENT_FORM_CONFIG),
  withTranslate,
  withPostBorderWidth,
  withFontClassName,
  withAuth,
)(CommentForm);

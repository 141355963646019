import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';

import {
  getPostEditorDraftKey,
  getPostEditorInitialDraftKey,
} from '../../../common/store/post-editor/post-editor-selectors';
import AnimatedLoader from '../../../common/components/animated-loader';
import PostEditorPage from './post-editor-page';

export class PostEditorPageLoader extends Component {
  componentWillUnmount() {
    this.props.cleanPostEditorState(this.props.draftKey);
  }

  render() {
    return (
      <AnimatedLoader isLoading={!this.props.initialDraftKey}>
        <PostEditorPage />
      </AnimatedLoader>
    );
  }
}

PostEditorPageLoader.propTypes = {
  initialDraftKey: PropTypes.string,
  draftKey: PropTypes.string,
  route: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  cleanPostEditorState: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  initialDraftKey: getPostEditorInitialDraftKey(state),
  draftKey: getPostEditorDraftKey(state),
  cleanPostEditorState: actions.cleanPostEditorState,
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
)(PostEditorPageLoader);

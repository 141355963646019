import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';
import withTranslate from '../../hoc/with-translate';
import styles from './header-button.scss';

const HeaderButton = ({ t, text, onClick, isMuted, ...otherProps }) => (
  <button
    className={classNames(styles.button, 'blog-button-primary-text-color', 'header-button', {
      [styles.isMuted]: isMuted,
    })}
    onClick={onClick}
    {...omit(otherProps, ['tReady', 'lng'])}
  >
    {t && t(text)}
  </button>
);

HeaderButton.propTypes = {
  t: PropTypes.func.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func,
  isMuted: PropTypes.bool,
};

export default withTranslate(HeaderButton);

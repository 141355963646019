import PropTypes from 'prop-types';
import React, { Component } from 'react';
import scrollParent from '../../../common/services/scroll-parent';
import { Gateway } from 'react-gateway';

const DEFAULT_FORM_HEIGHT = 60;

export class StickyFooter extends Component {
  static propTypes = {
    showAfter: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    containerHeight: PropTypes.number.isRequired,
    contentHeight: PropTypes.number.isRequired,
  };

  state = {
    showPixels: 0,
  };

  constructor(props) {
    super(props);

    this.showFooter = this.showFooter.bind(this);
  }

  showFooter() {
    if (!this.props.showAfter) {
      return;
    }

    const scrollTop = scrollParent().scrollTop;
    const scrollBottom = scrollTop + scrollParent().clientHeight;

    const formHeight = scrollBottom - this.props.showAfter;
    const roofFormHeight = formHeight < DEFAULT_FORM_HEIGHT ? formHeight : DEFAULT_FORM_HEIGHT;
    if (scrollBottom + roofFormHeight > this.props.showAfter) {
      this.setState({
        showPixels: roofFormHeight,
      });
    } else {
      this.setState({ showPixels: 0 });
    }
  }

  componentDidMount() {
    scrollParent().addEventListener('scroll', this.showFooter);
    this.showFooter();
  }

  componentWillUnmount() {
    scrollParent().removeEventListener('scroll', this.showFooter);
  }

  render() {
    const { children, containerHeight, contentHeight } = this.props;
    const styles = {
      position: 'fixed',
      bottom: -contentHeight + this.state.showPixels,
      right: 0,
      left: 0,
    };
    return (
      <div style={{ paddingBottom: `${containerHeight}px` }}>
        <Gateway into="footer">
          <div style={styles}>{children}</div>
        </Gateway>
      </div>
    );
  }
}

export default StickyFooter;

export function getElementsPositionToRootWindow(element, offsetPosition = {}, callback) {
  let offset = 0;
  const elementsRect = element.getBoundingClientRect();
  if (offsetPosition.center) {
    offset = screen.height / 2;
  }
  if (offsetPosition.quarter) {
    offset = screen.height / 4;
  }
  if (offsetPosition.top) {
    offset = screen.height / 10;
  }
  callback(elementsRect.top - offset);
}

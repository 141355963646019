import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { CommentingDisabledIcon } from '../../../common/components/icons/commenting-disabled-icon';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import styles from './commenting-disabled.scss';

export const CommentingDisabled = ({ message, contentFontClassName, borderWidth }) => {
  const containerClassName = classNames(
    styles.container,
    contentFontClassName,
    'blog-card-background-color',
    'blog-card-border-color',
    'blog-text-color',
  );

  return (
    <div data-hook="disabled-commenting" className={containerClassName} style={{ borderWidth }}>
      <span className={styles.wrapper}>
        <CommentingDisabledIcon className={classNames(styles.icon, 'blog-icon-fill')} />
        {message}
      </span>
    </div>
  );
};

CommentingDisabled.propTypes = {
  message: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  borderWidth: PropTypes.number.isRequired,
};

// prettier-ignore
export default flowRight(
  withFontClassName,
  withCardBorderWidth
)(CommentingDisabled);

import PropTypes from 'prop-types';
import React from 'react';

import { ModalCentered } from '../../../common/components/modals/base/modal-centered';
import Confirmation from '../../../common/components/confirmation';
import { DiscardIcon } from '../../../common/components/icons/discard-icon';
import withTranslate from '../../../common/hoc/with-translate';

export const DiscardPostSettingsModal = ({ closeModal, t }) => (
  <ModalCentered onClose={closeModal}>
    <Confirmation
      icon={DiscardIcon}
      title={t('discard-post-settings-modal.title')}
      confirm={t('discard-post-settings-modal.confirm-button-label')}
      cancel={t('discard-post-settings-modal.cancel-button-label')}
      onConfirm={() => closeModal('discard')}
      onCancel={closeModal}
    >
      <p>{t('discard-post-settings-modal.content')}</p>
    </Confirmation>
  </ModalCentered>
);

DiscardPostSettingsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(DiscardPostSettingsModal);

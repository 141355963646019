import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './multi-select-option.scss';

const MultiSelectOption = ({ name, value, label, checked, disabled, onChange, labelClassName }) => (
  <div className={classNames(styles.container, 'blog-post-description-font')} data-hook="multi-select-option">
    <input
      id={`op-${value}`}
      className={styles.checkbox}
      type="checkbox"
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <label className={getLabelClassName(checked, disabled, labelClassName)} htmlFor={`op-${value}`}>
      {label}
      <div className={classNames(styles.border, 'blog-text-border-color')} />
    </label>
  </div>
);

function getLabelClassName(checked, disabled, labelClassName) {
  return classNames(styles.label, labelClassName, {
    [styles.checked]: checked,
    [styles.disabled]: disabled,
    'blog-text-color': !checked,
    'blog-button-primary-text-color': checked,
    'blog-button-background-color': checked,
  });
}

MultiSelectOption.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
};

export default MultiSelectOption;

import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withTranslate from '../../common/hoc/with-translate';
import Button from '../../common/components/button';
import { HorizontalSeparator } from '../../common/components/separator';
import PostSettingsCategoryForm from './post-settings-category-form';
import styles from './post-settings-category-form-container.scss';

class PostSettingsCategoryFormContainer extends Component {
  state = { showForm: false };

  showForm = () => this.setState({ showForm: true });

  onSubmit = ({ values }) => {
    this.setState({ showForm: false });
    this.props.createCategory(values.label);
  };

  render() {
    const { categories, isMobile, t } = this.props;

    return (
      <div className={classNames(styles.container, { [styles.isMobile]: isMobile })}>
        {categories.length > 0 && <HorizontalSeparator className={styles.separator} />}
        {this.state.showForm ? (
          <PostSettingsCategoryForm onSubmit={this.onSubmit} />
        ) : (
          <Button
            className={styles.button}
            onClick={this.showForm}
            data-hook="post-settings-category_button"
            isSecondary
            {...(isMobile ? { type: Button.TYPE_FULL_WIDTH } : {})}
          >
            {t('post-settings-category-form.button')}
          </Button>
        )}
      </div>
    );
  }
}

PostSettingsCategoryFormContainer.propTypes = {
  categories: PropTypes.array.isRequired,
  createCategory: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

PostSettingsCategoryFormContainer.defaultProps = {
  isMobile: false,
};

export default flowRight(withTranslate)(PostSettingsCategoryFormContainer);

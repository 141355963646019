import React from 'react';
import { flowRight, pick } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import {
  ROUTE_404,
  ROUTE_LOGIN,
  ROUTE_CREATE_POST,
  ROUTE_EDIT_POST,
  ROUTE_PREVIEW_POST,
  ROUTE_EDIT_COMMENT,
  ROUTE_POST,
  ROUTE_LAST_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
} from '../../constants/routes';
import { getCurrentMatch } from '../../../common/router/router-selectors';

import App from '../../../common/components/app';
import ModalRoot from '../../components/modals/modal-root';
import MessageRoot from '../../components/messages/message-root';
import PostComponent from '../post-page';
import LastPostPage from '../last-post-page';
import PostEditorPageOrModal from '../post-editor-page-or-modal';
import NotFoundPage from '../../../common/containers/not-found-page';
import LoginPage from '../../../common/containers/login-page';
import CategoryMobileHeader from '../../../common/components/category-mobile-header';
import CategoryDesktopHeader from '../../../common/components/category-desktop-header';
import CategoriesLink from '../../../common/components/categories-link';

const PostCommentEditComponent = () => {
  return <div>Edit comment</div>;
};

const WrapComponent = Component => props => (
  <App
    main={<Component params={props.params} />}
    modalRoot={<ModalRoot />}
    messageRoot={<MessageRoot />}
    mobileHeader={<CategoryMobileHeader />}
    mobileMenu={<CategoriesLink />}
    desktopHeader={<CategoryDesktopHeader />}
  />
);

const PostEditor = WrapComponent(PostEditorPageOrModal);

const ROUTE_COMPONENT_MAP = {
  [ROUTE_404]: WrapComponent(NotFoundPage),
  [ROUTE_LOGIN]: WrapComponent(LoginPage),
  [ROUTE_CREATE_POST]: PostEditor,
  [ROUTE_EDIT_POST]: PostEditor,
  [ROUTE_PREVIEW_POST]: WrapComponent(PostComponent),
  [ROUTE_EDIT_COMMENT]: PostCommentEditComponent,
  [ROUTE_EDIT_COMMENT_ADVANCED_SLUG]: PostCommentEditComponent,
  [ROUTE_POST]: WrapComponent(PostComponent),
  [ROUTE_POST_ADVANCED_SLUG]: WrapComponent(PostComponent),
  [ROUTE_LAST_POST]: WrapComponent(LastPostPage),
};

const Router = ({ route, ...rest }) => {
  let Component = ROUTE_COMPONENT_MAP[route];

  if (!Component) {
    Component = () => <div>Route "{route}" - is not implemented</div>;
  }

  return <Component {...rest} />;
};

Router.propTypes = {
  route: PropTypes.string,
  pathname: PropTypes.string,
  sectionUrl: PropTypes.string,
  params: PropTypes.object,
};

const mapRuntimeToProps = state => ({
  ...pick(getCurrentMatch(state), ['route', 'pathname', 'sectionUrl', 'params']),
});

export default flowRight(connect(mapRuntimeToProps))(Router);

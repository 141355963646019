import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import withTranslate from '../../common/hoc/with-translate';
import { SettingsIcon } from '../../common/components/icons/settings-icon';
import styles from './post-settings-toggle.scss';

export const PostSettingsToggle = ({ t, className, ...containerProps }) => (
  <div className={classNames(className, styles.container)} {...omit(containerProps, ['tReady', 'lng'])}>
    <div className={styles.iconContainer}>
      <SettingsIcon className={styles.icon} />
    </div>
    <div className={styles.spacing} />
    <div className={styles.label}>{t('post-settings-label.title')}</div>
  </div>
);

PostSettingsToggle.propTypes = {
  className: PropTypes.string,
  containerProps: PropTypes.object,
  t: PropTypes.func.isRequired,
};

// prettier-ignore
export default withTranslate(PostSettingsToggle);

import PropTypes from 'prop-types';
import React from 'react';
import Header from '../../../common/components/header';
import HeaderBackButton from '../../../search/components/header-back-button';
import HeaderButton from '../../../common/components/header-button';

const AddCategoriesMobileHeader = ({ onCancel: handleCancel, onSubmit: handleSubmit, ...otherProps }) => (
  <Header
    left={<HeaderBackButton component="button" onClick={handleCancel} text={'header-back-button.cancel'} />}
    right={<HeaderButton onClick={handleSubmit} text="add-categories-mobile-header.submit-button-label" />}
    {...otherProps}
  />
);

AddCategoriesMobileHeader.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddCategoriesMobileHeader;

import PropTypes from 'prop-types';
import React from 'react';

import SymbolsLeftCounter from '../../common/components/symbols-left-counter';
import PostSettingsLabelWithTooltip from './post-settings-label-with-tooltip';
import styles from './post-settings-input-label.scss';

const PostSettingsInputLabel = ({ htmlFor, labelText, tooltipText, value, limit, showInfo }) => (
  <div className={styles.container}>
    <PostSettingsLabelWithTooltip labelProps={{ htmlFor }} labelText={labelText} tooltipText={tooltipText} />
    {limit && showInfo && (
      <div className={styles.counter} data-hook="post-settings-input-label__tooltip-counter">
        <SymbolsLeftCounter value={value} limit={limit} />
      </div>
    )}
  </div>
);

PostSettingsInputLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  value: PropTypes.string,
  limit: PropTypes.number,
  showInfo: PropTypes.bool,
};

export default PostSettingsInputLabel;

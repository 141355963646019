import { map, filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MultiSelectOption from './multi-select-option';
import styles from './multi-select.scss';

class MultiSelect extends Component {
  handleChange = event => {
    const itemValue = event.target.value;
    const { value, onChange } = this.props;
    const newValue =
      value.indexOf(itemValue) === -1
        ? [...value, itemValue]
        : filter(value, selectedValue => selectedValue !== itemValue);

    onChange(newValue);
  };

  render() {
    const { value, options, name, maxSelectedCount } = this.props;
    return (
      <fieldset className={styles.container}>
        <div className={styles.items}>
          {map(options, option => {
            const checked = value.indexOf(option.value) > -1;
            const disabled = Boolean(maxSelectedCount && !checked && value.length >= maxSelectedCount);
            return (
              <div className={styles.item} key={option.value}>
                <MultiSelectOption
                  name={name}
                  onChange={this.handleChange}
                  disabled={disabled}
                  checked={checked}
                  {...option}
                />
              </div>
            );
          })}
        </div>
      </fieldset>
    );
  }
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      labelClassName: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  maxSelectedCount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

MultiSelect.defaultProps = {
  value: [],
};

export default MultiSelect;

import PropTypes from 'prop-types';
import React from 'react';
import { ModalCentered } from '../../../common/components/modals/base/modal-centered';
import ShareForm from '../../../common/components/share-form';
import withTranslate from '../../../common/hoc/with-translate';

const ShareCommentModal = ({ closeModal, postSlug, t }) => (
  <ModalCentered onClose={closeModal}>
    <ShareForm title={t('share-comment-modal.share-comment')} path={`/${postSlug}`} />
  </ModalCentered>
);

ShareCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  postSlug: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(ShareCommentModal);

import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import {
  MODAL_TYPE_ADD_CATEGORIES,
  MODAL_POST_FORM_ACTIONS,
} from '@wix/communities-blog-client-common/dist/src/constants/modal-types';
import ActionSheet from '../../../common/components/action-sheet';
import Button from '../../../common/components/button';
import { PostPublishButton } from '../publish-button';
import withTranslate from '../../../common/hoc/with-translate';

const PostFormActionSheet = ({ openModal, closeModal, t, categoryIds }) => (
  <ActionSheet onBackdropClick={closeModal}>
    <Button
      type={Button.TYPE_FULL_WIDTH}
      isSecondary
      onClick={() => openModal(MODAL_TYPE_ADD_CATEGORIES, { formInitialValues: { categoryIds: categoryIds || [] } })}
    >
      {t('post-form-action-sheet.category-button-label')}
    </Button>
    <Button type={Button.TYPE_FULL_WIDTH} isSecondary onClick={() => closeModal('save')}>
      {t('post-form-action-sheet.save-button-label')}
    </Button>
    <PostPublishButton onSuccess={closeModal}>
      <Button type={Button.TYPE_FULL_WIDTH}>{t('post-form-action-sheet.publish-button-label')}</Button>
    </PostPublishButton>
  </ActionSheet>
);

PostFormActionSheet.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  t: PropTypes.func,
  categoryIds: PropTypes.array,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openModal: actions.openModal,
  closeModal: payload =>
    actions.closeModal({
      type: MODAL_POST_FORM_ACTIONS,
      ...payload,
    }),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(PostFormActionSheet);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Overlay } from 'react-overlays';

import Tooltip from '../tooltip';
import scrollParent from '../../services/scroll-parent';

class TooltipTrigger extends Component {
  state = {
    isTooltipVisible: false,
  };

  handleMouseEnter = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        isTooltipVisible: true,
      });
    }, 150);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timeout);
    this.setState({
      isTooltipVisible: false,
    });
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { className, children, TooltipElement, tooltipText } = this.props;
    const { isTooltipVisible } = this.state;

    return (
      <div className={className} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        {children(isTooltipVisible)}
        <Overlay
          show={isTooltipVisible}
          placement="top"
          container={scrollParent()}
          target={this}
          onHide={this.handleMouseLeave}
          rootClose
        >
          {TooltipElement || <Tooltip>{tooltipText}</Tooltip>}
        </Overlay>
      </div>
    );
  }
}

TooltipTrigger.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func.isRequired,
  TooltipElement: PropTypes.node,
  tooltipText: PropTypes.string,
};

export default TooltipTrigger;

import PropTypes from 'prop-types';
import React from 'react';

const SymbolsLeftCounter = ({ value = '', limit }) => {
  if (!limit) {
    return null;
  }

  const left = limit - value.length;

  return (
    <span>
      {left}/{limit}
    </span>
  );
};

SymbolsLeftCounter.propTypes = {
  limit: PropTypes.number,
  value: PropTypes.string,
};

export default SymbolsLeftCounter;

import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Interpolate } from 'react-i18next';
import classNames from 'classnames';
import { Img } from '@wix/communities-image-lib';

import CommentHeader from '../comment-header';
import CommentFooter from '../comment-footer';
import RichContentViewer from '../../../rich-content/components/rich-content-viewer';
import TimeAgo from '../../../common/components/time-ago';
import withTranslate from '../../../common/hoc/with-translate';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import styles from './comment.scss';
import withPostBorderWidth from '../../../common/hoc/with-post-border-width';

const MAX_PHOTO_HEIGHT = 600;

class Comment extends Component {
  componentDidMount() {
    const { reportBottomPosition } = this.props;
    if (reportBottomPosition) {
      reportBottomPosition(this.ref.offsetTop + this.ref.offsetHeight);
    }
  }

  renderImage() {
    const {
      comment: { image },
    } = this.props;

    if (!image) {
      return null;
    }

    return (
      <div>
        <Img className={styles.image} src={image} height={MAX_PHOTO_HEIGHT} />
      </div>
    );
  }

  setComment = ref => {
    this.ref = ref;
  };

  renderBody() {
    const { comment } = this.props;
    if (typeof comment.content === 'object') {
      return (
        <RichContentViewer
          initialRawState={comment.content}
          useDescriptionFontInEditor={false}
          isCompact
          relValue={'noreferrer nofollow'}
        />
      );
    }
  }

  renderEditDate() {
    const { comment } = this.props;

    if (!comment.editedDate) {
      return null;
    }

    return (
      <p className={styles.editDate}>
        <Interpolate i18nKey="comment.edited" timeAgo={<TimeAgo time={comment.editedDate} />} />
      </p>
    );
  }

  render() {
    const { comment, borderWidth, contentFontClassName } = this.props;
    const className = classNames(
      styles.comment,
      contentFontClassName,
      'blog-text-color',
      'blog-card-background-color',
      'blog-card-border-color',
      'comment',
    );
    return (
      <article ref={this.setComment} className={className} id={comment._id} style={{ borderWidth }} data-hook="comment">
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <CommentHeader comment={comment} />
          </div>
          {this.renderEditDate()}
          <div className={classNames(styles.content, 'comment__content')}>
            {this.renderBody()}
            {this.renderImage()}
          </div>
          <CommentFooter
            comment={comment}
            onLikeClick={this.props.onLikeClick}
            onReplyClick={this.props.onReplyClick}
            showReplyLink
          />
        </div>
      </article>
    );
  }
}

Comment.propTypes = {
  t: PropTypes.func,
  comment: PropTypes.object.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onReplyClick: PropTypes.func,
  reportBottomPosition: PropTypes.func,
  borderWidth: PropTypes.number.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

export default flowRight(withFontClassName, withTranslate, withPostBorderWidth)(Comment);

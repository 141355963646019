import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import TooltipTrigger from '../tooltip-trigger';
import styles from './icon-tooltip.scss';

class IconTooltip extends Component {
  renderIcon = isActive => {
    const { activeIconClassName, inactiveIconClassName, Icon } = this.props;
    const iconClassName = isActive ? activeIconClassName : inactiveIconClassName;
    return <Icon className={iconClassName} />;
  };

  render() {
    const { className, children, tooltipText, TooltipElement } = this.props;

    return (
      <TooltipTrigger
        tooltipText={tooltipText}
        TooltipElement={TooltipElement}
        className={classNames(styles.container, className)}
      >
        {children || this.renderIcon}
      </TooltipTrigger>
    );
  }
}

IconTooltip.propTypes = {
  className: PropTypes.string,
  activeIconClassName: PropTypes.string.isRequired,
  inactiveIconClassName: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  TooltipElement: PropTypes.node,
  Icon: PropTypes.func,
  children: PropTypes.func,
};

export default IconTooltip;

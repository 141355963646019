import PropTypes from 'prop-types';
import React from 'react';
import ImageLoader from '../../../common/components/image-loader';
import styles from './comment-form-image.scss';

const renderButton = (isLoading, handleDelete) => {
  if (isLoading) {
    return null;
  }

  return <button className={styles.button} onClick={handleDelete} />;
};
const renderOverlay = isLoading => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <ImageLoader type="mini" />
    </div>
  );
};
const CommentFormImage = ({ src, isLoading, onDelete }) => (
  <div className={styles.container}>
    <img className={styles.image} src={src} />
    {renderButton(isLoading, onDelete)}
    {renderOverlay(isLoading)}
  </div>
);

CommentFormImage.propTypes = {
  src: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CommentFormImage;

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './comment-form-wrapper.scss';
import withPostBorderWidth from '../../../common/hoc/with-post-border-width';

const CommentFormWrapper = ({ children, borderWidth }) => {
  const containerClassName = classNames(styles.container, 'blog-card-background-color', 'blog-card-border-color');

  return (
    <div className={containerClassName} style={{ borderWidth }}>
      {children}
    </div>
  );
};

CommentFormWrapper.propTypes = {
  children: PropTypes.node,
  borderWidth: PropTypes.number.isRequired,
};

export default withPostBorderWidth(CommentFormWrapper);

import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import PostPage from '../post-page';
import NoPostFound from '../no-post-found';
import { getLastPostSlug } from '../../selectors/last-post-slug-selectors';

class LastPostPage extends Component {
  render() {
    const { postSlug } = this.props;
    return postSlug ? <PostPage params={{ postSlug }} /> : <NoPostFound />;
  }
}

LastPostPage.propTypes = {
  postSlug: PropTypes.string,
};

const mapStateToProps = state => ({
  postSlug: getLastPostSlug(state),
});

export default connect(mapStateToProps)(LastPostPage);

import React from 'react';

export const CameraIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="16"
    viewBox="0 0 19 16"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M528,388H513a2,2,0,0,1-2-2V376a2,2,0,0,1,2-2h3a2,2,0,0,1,2-2h5a2,2,0,0,1,2,2h3a2,2,0,0,1,2,2v10A2,2,0,0,1,528,388Zm-7.5-11a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,520.5,377Zm0,6a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,520.5,383Z"
      transform="translate(-511 -372)"
    />
  </svg>
);

export const scrollBy = (x, y, smooth = true) => {
  try {
    window.scrollBy({
      top: y,
      left: x,
      behavior: smooth ? 'smooth' : null,
    });
  } catch (error) {
    window.scrollBy(x, y);
  }
};

import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../common/components/runtime-context';

import withTranslate from '../../common/hoc/with-translate';
import { getIsSaving } from '../../common/store/is-saving/is-saving-selectors';
// import { isPublished } from '../../common/selectors/is-published';
import Button from '../../common/components/button';
import ActionButton from '../../common/components/action-button';
import { SaveChangesIcon } from '../../common/components/icons/save-changes-icon';
import { PublishIcon } from '../../common/components/icons/publish-icon';
import Spinner from '../../common/components/spinner';
import DropdownButton from '../../common/components/dropdown-button';
import styles from './post-settings-buttons.scss';

const LABEL_SAVE = 'post-settings-buttons.save';
const LABEL_SAVE_AND_PUBLISH = 'post-settings-buttons.save-and-publish';

const PostSettingsButtons = ({ t, onCancel, onSubmit, isSaving, canPublish, isPublished, invalid }) => {
  const publish = () => canPublish && onSubmit(true);
  const save = () => onSubmit(false);

  const Icon = isPublished ? PublishIcon : SaveChangesIcon;

  const isMuted = isSaving || (isPublished && !canPublish) || invalid;

  const ctaProps = {
    className: styles.submit,
    onClick: isPublished ? publish : save,
    isMuted,
    useFixedColor: false,
    'data-hook': `post-settings__${isPublished ? 'publish' : 'save'}`,
  };
  const dropdownProps = {
    top: true,
    isDisabled: isMuted,
    buttonProps: {
      isMuted,
      useFixedColor: false,
      'data-hook': 'post-settings_dropdown-button',
    },
  };

  if (invalid) {
    ctaProps.tooltipText = t(
      isPublished ? 'post-settings-buttons.tooltip.published' : 'post-settings-buttons.tooltip.not-published',
    );
  }

  return (
    <div className={styles.container}>
      {!isSaving && (
        <Button
          id={PostSettingsButtons.CANCEL_BUTTON_ID}
          onClick={onCancel}
          isSecondary
          data-hook="post-settings__cancel"
        >
          {t('post-settings-buttons.cancel-label')}
        </Button>
      )}
      <div className={styles.spacing} />
      {isSaving ? (
        <Button {...ctaProps}>
          <Spinner className={styles.spinner} />
          {t('post-settings-buttons.saving')}
        </Button>
      ) : (
        <Button {...ctaProps}>{t(isPublished ? LABEL_SAVE_AND_PUBLISH : LABEL_SAVE)}</Button>
      )}
      <DropdownButton {...dropdownProps}>
        <ActionButton onClick={isPublished ? save : publish} data-hook="post-settings-form-buttons_save">
          <Icon className="blog-icon-fill" />
          {t(isPublished ? LABEL_SAVE : LABEL_SAVE_AND_PUBLISH)}
        </ActionButton>
      </DropdownButton>
    </div>
  );
};

PostSettingsButtons.CANCEL_BUTTON_ID = 'post-settings-buttons__cancel';

PostSettingsButtons.propTypes = {
  t: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isPublished: PropTypes.bool.isRequired,
  canPublish: PropTypes.bool.isRequired,
  invalid: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  isSaving: getIsSaving(state),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(PostSettingsButtons);

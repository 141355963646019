import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { convertToRaw, convertFromRaw } from '../../../common/services/post-utils-rce';

import { COMMENT_UPDATE } from '../../../common/components/message/messages';
import CommentFormWrapper from '../comment-form-wrapper';
import CommentForm from '../comment-form';

export class CommentEditForm extends Component {
  handleSubmit = ({ values }) => {
    const { comment, updateCommentPromisified, showMessage } = this.props;
    const content = convertToRaw(values.content);
    updateCommentPromisified({ ...comment, ...values, content }).then(() => showMessage(COMMENT_UPDATE));
  };

  render = () => {
    const { stopEditingComment, comment } = this.props;

    return (
      <CommentFormWrapper>
        <CommentForm
          formInitialValues={{
            content: convertFromRaw(comment.content),
            ownerId: comment.ownerId,
            ownerSiteMemberId: comment.ownerSiteMemberId,
          }}
          onSubmit={this.handleSubmit}
          onCancel={() => stopEditingComment()}
          formName={`comment-${comment._id}`}
          editedDate={comment.editedDate}
        />
      </CommentFormWrapper>
    );
  };
}

CommentEditForm.propTypes = {
  comment: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  stopEditingComment: PropTypes.func.isRequired,
  updateCommentPromisified: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  updateCommentPromisified: actions.updateCommentPromisified,
  stopEditingComment: actions.stopEditingComment,
  showMessage: actions.showMessage,
});

export default connect(mapRuntimeToProps)(CommentEditForm);

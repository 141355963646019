const NO_SCROLL_CLASS = 'no-scroll';
let scrollTop = 0;

export const enableScroll = () => {
  document.documentElement.classList.remove(NO_SCROLL_CLASS);
  window.scrollTo(0, scrollTop);
};
export const disableScroll = () => {
  scrollTop = window.scrollY || document.documentElement.scrollTop;
  document.documentElement.classList.add(NO_SCROLL_CLASS);
};

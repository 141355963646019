import React from 'react';

export const ConfirmIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M8.75 14.25L6 11.5l-1 .999 3.75 3.751L17 7.999 16 7z" />
  </svg>
);

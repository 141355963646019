import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Page from '../../../common/components/page';
import forDevice from '../../../common/hoc/for-device';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import styles from './post-editor-page-layout.scss';

const PostPageLayoutMobile = ({ children, className, ...otherProps }) => (
  <Page className={classNames(className, 'blog-card-background-color')} noSpacing {...otherProps}>
    {children}
  </Page>
);

PostPageLayoutMobile.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const PostPageLayoutDesktop = ({ children, className, borderWidth, ...otherProps }) => (
  <Page className={classNames(styles.container, className)} {...otherProps}>
    <div
      className={classNames(styles.content, 'blog-card-border-color', 'blog-card-background-color')}
      style={{ borderWidth }}
    >
      {children}
    </div>
  </Page>
);

PostPageLayoutDesktop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  borderWidth: PropTypes.number,
};

// prettier-ignore
export default forDevice(
  PostPageLayoutMobile,
  withCardBorderWidth(PostPageLayoutDesktop)
);

import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { getIsCommentsLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getCommentsByPostId, getCommentsByPostIdAndPage } from '../../../comments/selectors/comment-selectors';
import { getEntityCount } from '../../../common/selectors/pagination-selectors';
import { getEditedCommentId } from '../../../comments/selectors/edited-comment-id-selectors';
import { getPostBySlug } from '../../../common/selectors/post-selectors';
import LoadMore from '../../../common/components/load-more';
import Loader from '../../../common/components/loader';
import CommentList from '../../../comments/components/comment-list';
import withDeviceType from '../../../common/hoc/with-device-type';

export class PostCommentList extends Component {
  componentWillUnmount = () => {
    this.props.setIsLoaded('comments', this.props.postId, false);
  };

  render = () => {
    const { allComments, currentPageComments, isCommentsLoading, entityCount, isMobile } = this.props;
    if (!allComments.length) {
      return null;
    }

    if (isMobile) {
      return (
        <LoadMore
          loadMore={this.props.loadMore}
          loader={<Loader />}
          isLoading={isCommentsLoading}
          hasMore={allComments.length < entityCount}
          threshold={1500}
        >
          {this.renderCommentList(allComments)}
        </LoadMore>
      );
    }

    if (currentPageComments.length) {
      return this.renderCommentList(currentPageComments);
    }

    return null;
  };

  renderCommentList = comments => {
    const { incrementCommentLikeCount, editedCommentId } = this.props;
    return (
      <CommentList
        reportBottomPosition={this.props.setFirstCommentBottomPosition}
        comments={comments}
        onLikeClick={incrementCommentLikeCount}
        editedCommentId={editedCommentId}
      />
    );
  };
}

PostCommentList.propTypes = {
  editedCommentId: PropTypes.string,
  allComments: PropTypes.array.isRequired,
  currentPageComments: PropTypes.array.isRequired,
  entityCount: PropTypes.number,
  postId: PropTypes.string,
  isMobile: PropTypes.bool,
  isCommentsLoading: PropTypes.bool,
  setIsLoaded: PropTypes.func,
  loadMore: PropTypes.func,
  incrementCommentLikeCount: PropTypes.func.isRequired,
  setFirstCommentBottomPosition: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const post = getPostBySlug(state, ownProps.postSlug) || {};
  const page = ownProps.page;
  return {
    isCommentsLoading: getIsCommentsLoading(state, ownProps.postSlug),
    currentPageComments: getCommentsByPostIdAndPage(state, post._id, page),
    allComments: getCommentsByPostId(state, post._id),
    entityCount: getEntityCount(state, 'comments'),
    editedCommentId: getEditedCommentId(state),
    postId: post._id,
    incrementCommentLikeCount: actions.incrementCommentLikeCount,
    setIsLoaded: actions.setIsLoaded,
  };
};

export default flowRight(connect(mapRuntimeToProps), withDeviceType)(PostCommentList);

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ModalPage from './modal-page';
import ModalBackdrop from './modal-backdrop';
import ModalLayout from './modal-layout';
import { closeModalWithoutCallback } from './services/close-modal-without-callback';
import styles from './modal.scss';

export class ModalFromRight extends Component {
  handleClose = () => closeModalWithoutCallback(this.props.onClose);

  render() {
    const { className, children, showBackdrop, backdropClassName } = this.props;

    return (
      <ModalPage className={styles.fromRight} onClose={this.handleClose} showBackdrop={showBackdrop}>
        {showBackdrop && (
          <ModalBackdrop onClose={this.handleClose} className={backdropClassName} showCloseButton={false} />
        )}
        <ModalLayout className={className} children={children} />
      </ModalPage>
    );
  }
}

ModalFromRight.propTypes = {
  className: PropTypes.string,
  backdropClassName: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  showBackdrop: PropTypes.bool,
};

ModalFromRight.defaultProps = {
  showBackdrop: true,
};

export default ModalFromRight;

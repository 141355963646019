import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';

import { MODAL_TYPE_POST_EDITOR } from '@wix/communities-blog-client-common/dist/src/constants/modal-types';
import MobileFullScreenModal from '../../../common/components/mobile-full-screen-modal';
import PostForm from '../../components/post-form';
import PostEditorMobileHeader from '../post-editor-mobile-header';
import Loader from '../../../common/components/loader';
import {
  getPostEditorDraftKey,
  getPostEditorInitialPost,
  getPostEditorPost,
} from '../../../common/store/post-editor/post-editor-selectors';
import { getPostFormInitialValues } from '../../../common/services/post-utils-rce';

class PostEditorModal extends Component {
  state = { formInitialValues: getPostFormInitialValues(this.props.initialPost) };

  componentWillReceiveProps(nextProps) {
    if (this.props.initialPost !== nextProps.initialPost) {
      this.setState({ formInitialValues: getPostFormInitialValues(nextProps.initialPost) });
    }
  }

  componentWillUnmount() {
    this.props.cleanPostEditorState(this.props.draftKey);
  }

  handleCancel = () => {
    this.props.closeModal({ type: MODAL_TYPE_POST_EDITOR, resolve: this.props.post });
  };

  render() {
    const { initialPost } = this.props;

    if (!initialPost) {
      return <Loader bubbleClassName="blog-text-background-color" />;
    }

    const header = <PostEditorMobileHeader onCancel={this.handleCancel} />;
    return (
      <MobileFullScreenModal header={header}>
        <PostForm formInitialValues={this.state.formInitialValues} externalModalsEnabled />
      </MobileFullScreenModal>
    );
  }
}

PostEditorModal.propTypes = {
  initialPost: PropTypes.object,
  post: PropTypes.object,
  cleanPostEditorState: PropTypes.func.isRequired,
  draftKey: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, onwProps, actions) => ({
  initialPost: getPostEditorInitialPost(state),
  post: getPostEditorPost(state),
  draftKey: getPostEditorDraftKey(state),
  cleanPostEditorState: actions.cleanPostEditorState,
  closeModal: actions.closeModal,
});

export default connect(mapRuntimeToProps)(PostEditorModal);

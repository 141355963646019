import PropTypes from 'prop-types';
import React from 'react';

import { ModalCentered } from '../../../common/components/modals/base/modal-centered';
import Confirmation from '../../../common/components/confirmation';
import { DiscardIcon } from '../../../common/components/icons/discard-icon';
import withTranslate from '../../../common/hoc/with-translate';

export const DiscardPostDraftModal = ({ closeModal, t, postId }) => (
  <ModalCentered onClose={closeModal}>
    <Confirmation
      icon={DiscardIcon}
      title={t('discard-post-draft-modal.title')}
      confirm={t('discard-post-draft-modal.confirm-button-label')}
      cancel={t('discard-post-draft-modal.cancel-button-label')}
      onConfirm={() => closeModal(postId)}
      onCancel={closeModal}
    >
      <p>{t('discard-post-draft-modal.content')}</p>
    </Confirmation>
  </ModalCentered>
);

DiscardPostDraftModal.propTypes = {
  t: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  postId: PropTypes.string,
};

export default withTranslate(DiscardPostDraftModal);

import toArray from 'data-uri-to-u8';
import exif from 'exif-component';
import rotate from './exif-rotate';

const ORIENTATIONS = {
  'top-left': 1,
  'top-right': 2,
  'bottom-right': 3,
  'bottom-left': 4,
  'left-top': 5,
  'right-top': 6,
  'right-bottom': 7,
  'left-bottom': 8,
};

function urlToImage(url, fn) {
  const img = new Image();
  if (fn) {
    img.onload = fn.bind(null, img);
  }
  img.src = url;
  return img;
}

function fixOrientation(url, fn) {
  const buf = toArray(url);
  let categories = {};
  try {
    categories = exif(buf.buffer);
  } catch (err) {}

  let orientation = 0;
  if (categories.Orientation && typeof categories.Orientation.value === 'number') {
    orientation = categories.Orientation.value;
  } else if (ORIENTATIONS[categories.orientation]) {
    orientation = ORIENTATIONS[categories.orientation];
  }

  if (orientation === 0) {
    return fn(url);
  }

  urlToImage(url, img => {
    fn(rotate(img, orientation));
  });
}

export default fixOrientation;

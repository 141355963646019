import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import withTranslate from '../../../common/hoc/with-translate';

const CharacterCount = ({ t, count, ...rest }) => (
  <span {...omit(rest, ['tReady', 'lng'])}>{t('character-count.label', { count })}</span>
);

CharacterCount.propTypes = {
  t: PropTypes.func,
  count: PropTypes.number,
};

export default withTranslate(CharacterCount);

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Interpolate } from 'react-i18next';
import ProtectedButton from '../../../common/containers/protected-button';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withTranslate from '../../../common/hoc/with-translate';
import styles from './login-to-comment.scss';

export const LoginToComment = ({ contentFontClassName, t }) => (
  <div className={classNames(styles.container, contentFontClassName)}>
    <ProtectedButton tagName="div" className={classNames(styles.loginLink, 'blog-text-color')}>
      <Interpolate
        i18nKey="login-to-comment.login-to-join"
        login={<span className="blog-button-color">{t('login-to-comment.login')}</span>}
      />
    </ProtectedButton>
  </div>
);

LoginToComment.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(withFontClassName(LoginToComment));

import { flowRight, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import withDirection, { withDirectionPropTypes, DIRECTIONS } from 'react-with-direction';
import RecentPostsListItem from '../recent-posts-list-item';
import Dot from './dot';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import styles from './recent-post-carousel.scss';

const sliderProps = {
  arrows: false,
  className: styles.container,
  dots: true,
  useCSS: true,
  infinite: true,
  customPaging: Dot,
  centerMode: true,
  centerPadding: '30px',
  slidesToShow: 1,
  slidesToScroll: 1,
};

const RecentPostCarousel = ({ borderWidth, direction, posts = [], isMetadataFooterVisible }) => (
  <div dir="ltr">
    <Slider {...sliderProps}>
      {map(posts, post => (
        <div className={styles.slide} key={post._id} dir={direction === DIRECTIONS.RTL ? 'rtl' : undefined}>
          <div className={classNames(styles.postWrapper, 'blog-card-border-color')} style={{ borderWidth }}>
            <RecentPostsListItem post={post} isMetadataFooterVisible={isMetadataFooterVisible} />
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

RecentPostCarousel.propTypes = {
  ...withDirectionPropTypes,
  posts: PropTypes.array.isRequired,
  borderWidth: PropTypes.number,
  isMetadataFooterVisible: PropTypes.bool.isRequired,
};

export default flowRight(withCardBorderWidth, withDirection, withFeedMetadataSettings)(RecentPostCarousel);

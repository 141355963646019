import rotate from 'rotate-component';
import flip from 'flip-component';

const ORIENTATIONS = [
  { op: 'none', degrees: 0 },
  { op: 'flip-x', degrees: 0 },
  { op: 'none', degrees: 180 },
  { op: 'flip-y', degrees: 0 },
  { op: 'flip-x', degrees: 90 },
  { op: 'none', degrees: 90 },
  { op: 'flip-x', degrees: -90 },
  { op: 'none', degrees: -90 },
];

/**
 * Rotate `img` with orientation `n` when necessary.
 *
 * The `img` dimensions are updated as necessary to
 * reflect the rotation applied.
 *
 * @param {Image} img
 * @param {Number} n
 * @return {String} data uri
 */
function orient(img, n) {
  const o = ORIENTATIONS[n - 1];

  // canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // dims
  if (rotated(n)) {
    canvas.height = img.width;
    canvas.width = img.height;
  } else {
    canvas.width = img.width;
    canvas.height = img.height;
  }

  // flip
  if (o.op === 'flip-x') {
    flip(canvas, true, false);
  }
  if (o.op === 'flip-y') {
    flip(canvas, false, true);
  }

  // rotate
  if (o.degrees) {
    rotate(ctx, {
      degrees: o.degrees,
      x: canvas.width / 2,
      y: canvas.height / 2,
    });

    if (rotated(n)) {
      const d = canvas.width - canvas.height;
      ctx.translate(d / 2, -d / 2);
    }
  }

  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL('image/jpeg');
}

/**
 * Check if we need to change dims.
 */
function rotated(n) {
  return [5, 6, 7, 8].indexOf(n) > -1;
}

export default orient;

import { flowRight, attempt } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';

import getEmptyFields from '../../../common/services/get-empty-fields';
import { REQUIRE_POST_FIELDS } from '../../../common/components/message/messages';
import withPostPublish from '../../hoc/with-post-publish';

export class PublishButton extends Component {
  handleClick = () => {
    const {
      fastForm: { errors },
      onSuccess,
      showMessage,
    } = this.props;
    const emptyFields = getEmptyFields(errors);

    if (emptyFields.length) {
      showMessage(REQUIRE_POST_FIELDS, emptyFields);
    } else {
      this.props.publishPost().then(() => attempt(onSuccess));
    }
  };

  render = () => {
    const { children } = this.props;
    return React.cloneElement(React.Children.only(children), {
      onClick: this.handleClick,
    });
  };
}

PublishButton.propTypes = {
  onSuccess: PropTypes.func,
  showMessage: PropTypes.func,
  fastForm: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  showMessage: actions.showMessage,
});

export const PostPublishButton = flowRight(
  connect(mapRuntimeToProps),
  withPostPublish({ skipInitialize: true }),
)(PublishButton);

import { POST_WIDGET_NAME } from '../viewer-script/constants/widgets';
import { withViewer } from '../common/hoc/with-viewer';
import AppRoot from './containers/app-root';

const isProduction = process.env.NODE_ENV === 'production';
const stylesConfig = {
  ltrCssPath: isProduction ? 'post-page-viewer.min.css' : 'post-page-viewer.css',
  rtlCssPath: isProduction ? 'post-page-viewer.rtl.min.css' : 'post-page-viewer.rtl.css',
};

export default withViewer(AppRoot, stylesConfig, POST_WIDGET_NAME);

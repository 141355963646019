import { map, find } from 'lodash';
import { createSelector } from 'reselect';
import { EXPERIMENT_USE_SITE_MEMBERS_ID } from '@wix/communities-blog-experiments';
import isEnabled from '@wix/communities-blog-client-common/dist/src/services/is-experiment-enabled';
import { getExperiments } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';

export const getWriters = state => state.writers;

export const getWritersForSelect = createSelector([getWriters, getExperiments], (writers, experiments) => {
  const isUseSiteMembersIdExperimentEnabled = isEnabled(experiments, EXPERIMENT_USE_SITE_MEMBERS_ID);

  return map(writers, writer => {
    const value = isUseSiteMembersIdExperimentEnabled ? writer.siteMemberId : writer._id;

    return { value, label: writer.name };
  });
});

export const getWriterSiteMemberIdById = (writers, _id) => {
  const writer = find(writers, writer => writer._id === _id);

  return writer.siteMemberId;
};

export const getWriterIdBySiteMemberId = (writers, siteMemberId) => {
  const writer = find(writers, writer => writer.siteMemberId === siteMemberId);

  return writer._id;
};

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import styles from './card-overlay.scss';

const CardOverlay = ({ children, cardBackgroundColor: backgroundColor, ...otherProps }) => (
  <div
    {...otherProps}
    className={classNames(styles.container, 'blog-default-card-background-color')}
    style={{ backgroundColor }}
  >
    {children}
  </div>
);

CardOverlay.propTypes = {
  children: PropTypes.node,
  cardBackgroundColor: PropTypes.string.isRequired,
};

export default withCardBackgroundColor(CardOverlay);

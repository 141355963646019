import { scrollBy } from './scroll-by';
import { getElementsPositionToRootWindow } from './get-elements-position';

export const scrollToComment = commentId => {
  const comment = document.getElementById(commentId);
  if (!comment) {
    return;
  }

  getElementsPositionToRootWindow(comment, { quarter: true }, y => {
    scrollBy(0, y, false);
    comment.focus();
  });
};

import React from 'react';
import withDeviceType from './with-device-type';

const forMobileOnly = Component => {
  return withDeviceType(({ isMobile, ...props }) => {
    return isMobile ? <Component {...props} /> : null;
  });
};

export default forMobileOnly;

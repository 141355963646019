import { ContentState, EditorState, AtomicBlockUtils } from '@wix/draft-js';
import { createWithContent } from 'wix-rich-content-editor/dist/lib/editorStateConversion.cjs';

export default function convertSimpleCommentToContentState(comment) {
  const contentState = ContentState.createFromText(comment.content || '');
  let editorState = createWithContent(contentState);

  if (comment.image) {
    const props = {
      src: comment.image,
      isLoading: false,
    };

    const entityKey = editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'IMMUTABLE', props)
      .getLastCreatedEntityKey();
    editorState = EditorState.moveSelectionToEnd(editorState);
    editorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
  }

  return {
    ownerId: comment.ownerId,
    ownerSiteMemberId: comment.ownerSiteMemberId,
    content: editorState.getCurrentContent(),
  };
}

import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import styles from './spinner.scss';

export const Spinner = ({ size, ...props }) => {
  return (
    <div {...props}>
      <div className={styles.spinner} style={{ width: size, height: size }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-9 -9 18 18"
          className={classNames(styles.lightArc, 'blog-button-primary-icon-stroke')}
        >
          <path
            strokeWidth="2"
            d="M -4.4083893921935475 6.067627457812106 A 7.5 7.5 0 1 0 4.592425496802575e-16 -7.5"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-9 -9 18 18"
          className={classNames(styles.darkArc, 'blog-button-primary-icon-stroke')}
        >
          <path strokeWidth="2" d="M 4.408389392193548 6.067627457812106 A 7.5 7.5 0 0 0 4.592425496802575e-16 -7.5" />
        </svg>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
};

Spinner.defaultProps = {
  size: '1em',
};

export default Spinner;

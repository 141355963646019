import { get, filter, find, head } from 'lodash';

import { ENTITY_TYPE_COMMENTS } from '@wix/communities-blog-client-common/dist/src/constants/entity-types';
import { getEntitiesByPage, getCurrentPageEntities, getEntityCount } from '../../common/selectors/pagination-selectors';

const getComments = state => state.comments;

const getEntityIdsByPage = (state, page) => get(getEntitiesByPage(state, ENTITY_TYPE_COMMENTS), page, []);

export const getCommentsByPostId = (state, postId) => getComments(state)[postId] || [];
export const getCommentsByPostIdAndPage = (state, postId, page) => {
  const entityIds = getEntityIdsByPage(state, page);
  const comments = getComments(state)[postId] || [];
  return filter(comments, comment => entityIds.indexOf(comment._id) > -1);
};

export const getCommentsCount = state => getEntityCount(state, ENTITY_TYPE_COMMENTS);

export const getComment = (state, commentId, postId) =>
  find(getCommentsByPostId(state, postId), comment => comment._id === commentId);

export const getFocusCommentId = state => head(getCurrentPageEntities(state, ENTITY_TYPE_COMMENTS));

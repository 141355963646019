import { get, cloneDeep } from 'lodash';
import {
  convertFromRaw as fromRaw,
  convertToRaw as toRaw,
} from 'wix-rich-content-editor/dist/lib/editorStateConversion.cjs';

export const getPostFormInitialValues = (post = {}) => {
  const initialValues = {
    categoryIds: get(post, 'categoryIds', []),
    ...get(post, 'draft', {}),
  };
  const content = get(post, 'draft.content');
  if (content) {
    initialValues.content = convertFromRaw(content);
  }
  return initialValues;
};

export const convertPostDraftContentToContentState = (post = {}) => ({
  ...post,
  draft: getPostFormInitialValues(post),
});

export const convertDraftContentStateToContent = (draft = {}) => {
  const raw = convertToRaw(draft.content);
  return {
    ...draft,
    content: {
      ...raw,
      entityMap: cloneDeep(raw.entityMap),
    },
  };
};

export const convertFromRaw = fromRaw;

export const convertToRaw = contentState => {
  const content = toRaw(contentState);
  content.blocks.forEach(block => {
    const data = block.data;
    if (get(data, 'dynamicStyles.toJS')) {
      data.dynamicStyles = data.dynamicStyles.toJS();
    }
  });
  return content;
};

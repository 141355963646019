import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';

import { isPublished } from '@wix/communities-blog-universal/dist/src/post-utils';
import { FIELD_ERROR_REQUIRED } from '@wix/communities-blog-client-common/dist/src/constants/field-errors';
import { getPostEditorPost } from '../../../common/store/post-editor/post-editor-selectors';
import { isScheduled } from '../../../common/selectors/post-selectors';
import { getIsRevertingToDraft } from '../../../common/store/is-loading/is-loading-selectors';
import withAuth from '../../../common/hoc/with-auth';
import getContentStateCharacterCount from '../../../common/services/get-content-state-character-count';
import PostFormSavingStatus from './post-form-saving-status';
import PostFormDropdownButton from './post-form-dropdown-button';
import withTranslate from '../../../common/hoc/with-translate';
import Button from '../../../common/components/button';
import styles from './post-form-action-bar.scss';

export const PostFormActionBar = ({
  t,
  fastForm,
  isCtaDisabled,
  isPublished,
  isScheduled,
  forPublicUser,
  isRevertingToDraft,
  onClickSave,
  onClickPublish,
  onClickCancel,
}) => {
  const { errors, values } = fastForm;

  const getCtaTooltipText = () => {
    if (errors.title && errors.content) {
      return t('post-form.require-fields');
    }

    if (errors.title) {
      return t('post-form.require-title');
    }

    if (errors.content) {
      return errors.content === FIELD_ERROR_REQUIRED ? t('post-form.require-content') : t('post-form.uploading');
    }
  };

  const renderPublishButton = () => {
    const isDisabled = isCtaDisabled || isRevertingToDraft;

    let className;
    let buttonText;
    let onClick;

    if (isScheduled) {
      className = 'post-form__update-schedule-button';
      buttonText = t('post-form.update-scheduled');
      onClick = this.handleCancelClick;
    } else {
      className = isPublished ? 'post-form__update-button' : 'post-form__publish-button';
      buttonText = t(isPublished ? 'post-form.update' : 'post-form.publish');
      onClick = !isDisabled ? forPublicUser(onClickPublish) : undefined;
    }

    return (
      <Button
        className={className}
        onClick={onClick}
        tooltipText={getCtaTooltipText()}
        isMuted={!isScheduled && isDisabled}
        useFixedColor={false}
        data-hook={isScheduled ? 'post-form__update-schedule-button' : 'post-form__publish-button'}
      >
        {buttonText}
      </Button>
    );
  };

  const isPostFormFieldsEmpty = Boolean(values.title && getContentStateCharacterCount(values.content));

  return (
    <div className={styles.desktopOnly}>
      <div className={styles.footer}>
        <PostFormSavingStatus />
        <div className={styles.buttons}>
          <span className={styles.button} data-hook="post-form__cancel-button">
            <Button isSecondary onClick={onClickCancel} useFixedColor={false}>
              {t('post-form.cancel')}
            </Button>
          </span>
          <span className={styles.button}>
            {renderPublishButton()}
            <PostFormDropdownButton
              buttonProps={{ id: PostFormActionBar.FOCUSABLE_ID_POST_ACTIONS, useFixedColor: false }}
              isPostFormFieldsEmpty={isPostFormFieldsEmpty}
              isCtaDisabled={isCtaDisabled}
              onClickSave={onClickSave}
              onClickPublish={onClickPublish}
              onClickCancel={onClickCancel}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

PostFormActionBar.FOCUSABLE_ID_POST_ACTIONS = 'post-form__post-actions';

PostFormActionBar.propTypes = {
  isPublished: PropTypes.bool,
  isScheduled: PropTypes.bool,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  isRevertingToDraft: PropTypes.bool.isRequired,
  fastForm: PropTypes.object,
  isCtaDisabled: PropTypes.bool,
  onClickSave: PropTypes.func,
  onClickPublish: PropTypes.func,
  onClickCancel: PropTypes.func,
};

const mapRuntimeToProps = state => {
  const post = getPostEditorPost(state) || {};
  return {
    isPublished: isPublished(post),
    isScheduled: isScheduled(post),
    isRevertingToDraft: getIsRevertingToDraft(state),
  };
};

export default flowRight(withAuth, withTranslate, connect(mapRuntimeToProps))(PostFormActionBar);

import PropTypes from 'prop-types';
import React from 'react';
import styles from './action-sheet.scss';
import { ModalFromBottom } from '../modals/base/modal-from-bottom';

const ActionSheet = ({ children, onBackdropClick }) => (
  <ModalFromBottom onClose={onBackdropClick} className={styles.container}>
    {React.Children.map(children, child => (
      <div className={styles.item}>{child}</div>
    ))}
  </ModalFromBottom>
);

ActionSheet.propTypes = {
  children: PropTypes.node,
  cardBackgroundColor: PropTypes.string,
  onBackdropClick: PropTypes.func,
};

export default ActionSheet;

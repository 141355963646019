import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import withAuth from '../../hoc/with-auth';
import ProfileLink from '../link/profile-link';

const CurrentUserProfileLink = ({ children, currentUser }) => <ProfileLink user={currentUser}>{children}</ProfileLink>;

CurrentUserProfileLink.propTypes = {
  children: PropTypes.node,
  currentUser: PropTypes.object,
};

export default flowRight(withAuth)(CurrentUserProfileLink);

import { POST_SEO_DESCRIPTION_MAXLENGTH } from '@wix/communities-blog-universal/dist/src/constants/post';

import { getContentText } from '../../common/services/content-text';

const limitLength = (text = '', length) => text.substring(0, length);

export const getPostExcerpt = ({ content, excerpt }) =>
  limitLength(excerpt || getContentText(content), POST_SEO_DESCRIPTION_MAXLENGTH);

export const getPostSeoDescription = ({ seoDescription, content, excerpt }) =>
  seoDescription || getPostExcerpt({ content, excerpt });

import { map, get, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';

import { POST_CATEGORY_MAX_COUNT } from '@wix/communities-blog-universal/dist/src/constants/post';
import { PUB_SUB_CHANNEL_APP } from '@wix/communities-blog-client-common/dist/src/constants/pub-sub-channels';
import PostSettingsCategoryFormContainer from '../../../post-settings/components/post-settings-category-form-container';
import { AddCategoryIcon } from '../icons/add-category-icon';
import MultiSelect from '../multi-select';
import { HorizontalSeparator } from '../separator';
import withTranslate from '../../hoc/with-translate';
import withDeviceType from '../../hoc/with-device-type';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import styles from './category-select.scss';

const CategorySelect = ({
  t,
  categories,
  value,
  onChange,
  can,
  createCategoryFromLabel,
  addCategory,
  contentFontClassName,
  isMobile,
}) => {
  const options = map(categories, ({ _id, menuLabel }) => ({
    id: _id,
    value: _id,
    label: menuLabel,
  }));
  const count = get(value, 'length', 0);

  const createCategory = label =>
    createCategoryFromLabel(label).then(category => {
      if (get(value, 'length', 0) < POST_CATEGORY_MAX_COUNT) {
        onChange([...value, category._id]);
      }
      addCategory(category, { publish: [PUB_SUB_CHANNEL_APP] });
    });

  return (
    <div className={classNames(styles.container, contentFontClassName, 'blog-text-color')} data-hook="category-select">
      <div className={styles.header}>
        <AddCategoryIcon className={classNames(styles.icon, 'blog-icon-fill')} />
        <span className={classNames(styles.titleWrapper, 'blog-post-title-font')}>
          <h2 className={styles.headerTitle}>{t('post-settings-category-select.hint')}</h2>
        </span>
        <span className={styles.headerCounter}>
          {count} / {POST_CATEGORY_MAX_COUNT}
        </span>
        <HorizontalSeparator className={styles.separator} />
      </div>
      <div className={styles.content}>
        {options.length ? (
          <div className={styles.multiSelect}>
            <MultiSelect
              name="categories"
              maxSelectedCount={POST_CATEGORY_MAX_COUNT}
              value={value}
              options={options}
              onChange={onChange}
            />
          </div>
        ) : (
          <p className={styles.text}>{t('post-settings-category-select.no-categories-hint')}</p>
        )}
        {can('create', 'category') && (
          <div className={classNames({ [styles.categoryFormMobileWrapper]: isMobile })}>
            <PostSettingsCategoryFormContainer createCategory={createCategory} categories={categories} isMobile />
          </div>
        )}
      </div>
    </div>
  );
};

CategorySelect.propTypes = {
  t: PropTypes.func,
  addCategory: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  cardBackgroundColor: PropTypes.string,
  categories: PropTypes.array.isRequired,
  createCategoryFromLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.array,
  isMobile: PropTypes.bool.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, onwProps, actions) => ({
  createCategoryFromLabel: actions.createCategoryFromLabelPromisified,
  addCategory: actions.addCategory,
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withPermissions,
  withCardBackgroundColor,
  withDeviceType,
  withFontClassName
)(CategorySelect);

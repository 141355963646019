import PropTypes from 'prop-types';
import React from 'react';
import Comment from '../comment';
import CommentEditForm from '../comment-edit-form';
import { HorizontalSeparator } from '../../../common/components/separator';
import withDeviceType from '../../../common/hoc/with-device-type';
import styles from './comment-list.scss';

const CommentList = ({ comments, onLikeClick, onReplyClick, reportBottomPosition, isMobile, editedCommentId }) => (
  <div id="comments" className={styles.commentList}>
    {comments.map((comment, index) =>
      renderItem({
        isLast: index === comments.length - 1,
        comment,
        index,
        onLikeClick,
        onReplyClick,
        reportBottomPosition,
        isMobile,
        editedCommentId,
      }),
    )}
  </div>
);

function renderItem({
  isLast,
  comment,
  index,
  onLikeClick,
  onReplyClick,
  reportBottomPosition,
  isMobile,
  editedCommentId,
}) {
  const key = comment._id;
  const result = [
    editedCommentId === key
      ? renderCommentForm({ key, comment })
      : renderComment({ key, index, comment, reportBottomPosition, onLikeClick, onReplyClick }),
  ];

  if (isMobile && !isLast) {
    result.push(<HorizontalSeparator className={styles.separator} key={`${key}-separator`} />);
  }
  return result;
}

/* eslint-disable react/prop-types */
function renderCommentForm({ key, comment }) {
  return <CommentEditForm key={key} comment={comment} />;
}
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
function renderComment({ key, index, comment, reportBottomPosition, onLikeClick, onReplyClick }) {
  return (
    <Comment
      reportBottomPosition={index === 0 ? reportBottomPosition : null}
      key={key}
      comment={comment}
      onLikeClick={onLikeClick}
      onReplyClick={onReplyClick}
    />
  );
}
/* eslint-enable react/prop-types */

CommentList.propTypes = {
  comments: PropTypes.array.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onReplyClick: PropTypes.func,
  reportBottomPosition: PropTypes.func,
  isMobile: PropTypes.bool,
  editedCommentId: PropTypes.string,
};

export default withDeviceType(CommentList);
